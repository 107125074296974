/*ONLINE CHECKIN*/
.OC-bg_color {
    background-color: var(--OC-bg_color);
  }

.dark .OC-bg_color {
  background-color: theme('colors.gxp_dark_2');
}

.OC-title_color {
  color: var(--OC-title_color);
}

.dark .OC-title_color {
  color: text-white
}

.OC-title_size {
  font-size: var(--OC-title_size);
}

.OC-title_font_family {
  font-family: var(--OC-title_font_family) !important;
}

.OC-title_variant {
  font-style: var(--OC-title_variant-style);
  font-weight: var(--OC-title_variant-weight);
}

/*HEADER*/
.OC-Header-title_color {
  color: var(--OC-Header-title_color);
}

.dark.OC-Header-title_color {
  color: white
}

.OC-Header-title_size {
  font-size: var(--OC-Header-title_size);
}

.OC-Header-title_font_family {
  font-family: var(--OC-Header-title_font_family) !important;
}

.OC-Header-title_variant {
  font-style: var(--OC-Header-title_variant-style);
  font-weight: var(--OC-Header-title_variant-weight);
}

.OC-Header-text_color {
  color: var(--OC-Header-text_color);
}

.dark.OC-Header-text_color {
  color: white
}

.OC-Header-text_size {
  font-size: var(--OC-Header-text_size);
}

.OC-Header-text_font_family {
  font-family: var(--OC-Header-text_font_family) !important;
}

.OC-Header-text_variant {
  font-style: var(--OC-Header-text_variant-style);
  font-weight: var(--OC-Header-text_variant-weight);
}

.OC-Header-bar_bg_color {
  background-color: var(--OC-Header-bar_bg_color);
}

.dark.OC-Header-bar_bg_color {
  background-color: var(--OC-Header-bar_bg_color);
}

.OC-Header-bar_border_color {
  border-color: var(--OC-Header-bar_border_color);
}

.dark.OC-Header-bar_border_color {
  border-color: white;
}

.OC-Header-bar_border_width {
  border-width: var(--OC-Header-bar_border_width);
}

.OC-Header-bar_border_rounded {
  border-radius: var(--OC-Header-bar_border_rounded);
}

.OC-Header-bar_border_position {
  border-style: var(--OC-Header-bar_border_position);
}

/*STEPPER*/

.OC-Stepper-border_color {
  border-color: var(--OC-Stepper-border_color);
}

.dark.OC-Stepper-border_color {
  border-color: white;
}

.OC-Stepper-active_color {
  color: var(--OC-Stepper-active_color);
  border-color: var(--OC-Stepper-active_color);
}

.OC-Stepper-text_color {
  color: var(--OC-Stepper-text_color);
}

.OC-Stepper-text_font_family {
  font-family: var(--OC-Stepper-text_font_family) !important;
}

.OC-Stepper-text_variant {
  font-style: var(--OC-Stepper-text_variant-style);
  font-weight: var(--OC-Stepper-text_variant-weight);
}

.OC-Stepper-label_color {
  color: var(--OC-Stepper-label_color);
}

.OC-Stepper-label_font_family {
  font-family: var(--OC-Stepper-label_font_family) !important;
}

.OC-Stepper-label_variant {
  font-style: var(--OC-Stepper-label_variant-style);
  font-weight: var(--OC-Stepper-label_variant-weight);
}

.OC-Stepper-label_size {
  font-size: var(--OC-Stepper-label_size);
}

/*FORM*/

.OC-Form-title_color {
  color: var(--OC-Form-title_color);
}

.OC-Form-title_size {
  font-size: var(--OC-Form-title_size);
}

.OC-Form-title_font_family {
  font-family: var(--OC-Form-title_font_family) !important;
}

.OC-Form-title_variant {
  font-style: var(--OC-Form-title_variant-style);
  font-weight: var(--OC-Form-title_variant-weight);
}

.OC-Form-text_color {
  color: var(--OC-Form-text_color);
}

.OC-Form-text_size {
  font-size: var(--OC-Form-text_size);
}

.OC-Form-text_font_family {
  font-family: var(--OC-Form-text_font_family) !important;
}

.OC-Form-text_variant {
  font-style: var(--OC-Form-text_variant-style);
  font-weight: var(--OC-Form-text_variant-weight);
}

.OC-Form-bg_color {
  background-color: var(--OC-Form-bg_color);
}

.OC-Form-border_color {
  border-color: var(--OC-Form-border_color);
}

.OC-Form-border_rounded {
  border-radius: var(--OC-Form-border_rounded);
}

.OC-Form-border_position {
  border-style: var(--OC-Form-border_position);
}

.OC-Form-border_width {
  border-width: var(--OC-Form-border_width);
}

/*FORM FIELDS*/

.OC-FormField-label_color {
  color: var(--OC-FormField-label_color);
}

.OC-FormField-label_size {
  font-size: var(--OC-FormField-label_size);
}

.OC-FormField-label_font_family {
  font-family: var(--OC-FormField-label_font_family) !important;
}

.OC-FormField-label_variant {
  font-style: var(--OC-FormField-label_variant-style);
  font-weight: var(--OC-FormField-label_variant-weight);
}

.OC-FormField-label_location {
  transform: translateY(var(--OC-FormField-label_location)) translateX(12px);
}

.OC-FormField-label_location_phone {
  transform: translateY(var(--OC-FormField-label_location)) translateX(155px);
}

.OC-FormField-text_color {
  color: var(--OC-FormField-text_color);
}

.OC-FormField-text_size {
  font-size: var(--OC-FormField-text_size);
}

.OC-FormField-text_font_family {
  font-family: var(--OC-FormField-text_font_family) !important;
}

.OC-FormField-text_variant {
  font-style: var(--OC-FormField-text_variant-style);
  font-weight: var(--OC-FormField-text_variant-weight);
}

.OC-FormField-bg_color {
  background-color: var(--OC-FormField-bg_color);
}

.OC-FormField-icon_color {
  color: var(--OC-FormField-icon_color);
}

.OC-FormField-icon_border_color {
  border-color: var(--OC-FormField-icon_color);
}

.OC-FormField-border_color {
  border-color: var(--OC-FormField-border_color);
}


.OC-FormField-border_rounded {
  border-radius: var(--OC-FormField-border_rounded);
}

.OC-FormField-border_rounded_phone {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--OC-FormField-border_rounded);
  border-bottom-right-radius: var(--OC-FormField-border_rounded);
}

.OC-FormField-border_rounded_phone_select {
  border-top-left-radius: var(--OC-FormField-border_rounded);
  border-bottom-left-radius: var(--OC-FormField-border_rounded);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.OC-FormField-border_position {
  border-style: var(--OC-FormField-border_position);
}

.OC-FormField-border_width {
  border-width: var(--OC-FormField-border_width);
}

.OC-FormField-placeholder_color::placeholder {
  color: var(--OC-FormField-placeholder_color) !important;
}

.OC-FormField-placeholder_font_family::placeholder {
  font-family: var(--OC-FormField-placeholder_font_family) !important;
}

.OC-FormField-placeholder_variant::placeholder {
  font-style: var(--OC-FormField-placeholder_variant-style) !important;
  font-weight: var(--OC-FormField-placeholder_variant-weight) !important;
}

input[type="date"]::before {
  content: attr(placeholder);
  color: gray;
  position: absolute;
  top: 10px;
  left: 12px;
  pointer-events: none;
  font-size: 14px;
}

/*BUTTONS*/

.OC-Button1-bg_color {
  background-color: var(--OC-Button1-bg_color);
}

.OC-Button1-icon_color {
  color: var(--OC-Button1-icon_color);
}

.OC-Button1-border_color {
  border-color: var(--OC-Button1-border_color);
}

.OC-Button2-bg_color {
  background-color: var(--OC-Button2-bg_color);
}

.OC-Button2-icon_color {
  color: var(--OC-Button2-icon_color);
}

.OC-Button2-border_color {
  border-color: var(--OC-Button2-border_color);
}

/*CONSENT*/

.OC-Consent-bg_color {
  background-color: var(--OC-Consent-bg_color);
}

.OC-Consent-border_color {
  border-color: var(--OC-Consent-border_color);
}

.OC-Consent-border_rounded {
  border-radius: var(--OC-Consent-border_rounded);
}

.OC-Consent-border_position {
  border-style: var(--OC-Consent-border_position);
}

.OC-Consent-border_width {
  border-width: var(--OC-Consent-border_width);
}

.OC-Consent-text_color {
  color: var(--OC-Consent-text_color);
}

.OC-Consent-text_size {
  font-size: var(--OC-Consent-text_size);
}

.OC-Consent-text_font_family {
  font-family: var(--OC-Consent-text_font_family) !important;
}

.OC-Consent-text_variant {
  font-style: var(--OC-Consent-text_variant-style);
  font-weight: var(--OC-Consent-text_variant-weight);
}

.OC-Consent-title_color {
  color: var(--OC-Consent-title_color);
}

.OC-Consent-title_size {
  font-size: var(--OC-Consent-title_size);
}

.OC-Consent-title_font_family {
  font-family: var(--OC-Consent-title_font_family) !important;
}

.OC-Consent-title_variant {
  font-style: var(--OC-Consent-title_variant-style);
  font-weight: var(--OC-Consent-title_variant-weight);
}

.OC-Consent-icon_color {
  color: var(--OC-Consent-icon_color);
}

/*GENERAL TERMS*/

.OC-GeneralTerm-bg_color {
  background-color: var(--OC-GeneralTerm-bg_color);
}

.OC-GeneralTerm-border_color {
  border-color: var(--OC-GeneralTerm-border_color);
}

.OC-GeneralTerm-border_rounded {
  border-radius: var(--OC-GeneralTerm-border_rounded);
}

.OC-GeneralTerm-border_position {
  border-style: var(--OC-GeneralTerm-border_position);
}

.OC-GeneralTerm-border_width {
  border-width: var(--OC-GeneralTerm-border_width);
}

.OC-GeneralTerm-text_color {
  color: var(--OC-GeneralTerm-text_color);
}

.OC-GeneralTerm-text_size {
  font-size: var(--OC-GeneralTerm-text_size);
}

.OC-GeneralTerm-text_font_family {
  font-family: var(--OC-GeneralTerm-text_font_family) !important;
}

.OC-GeneralTerm-text_variant {
  font-style: var(--OC-GeneralTerm-text_variant-style);
  font-weight: var(--OC-GeneralTerm-text_variant-weight);
}

.OC-GeneralTerm-title_color {
  color: var(--OC-GeneralTerm-title_color);
}

.OC-GeneralTerm-title_size {
  font-size: var(--OC-GeneralTerm-title_size);
}

.OC-GeneralTerm-title_font_family {
  font-family: var(--OC-GeneralTerm-title_font_family) !important;
}

.OC-GeneralTerm-title_variant {
  font-style: var(--OC-GeneralTerm-title_variant-style);
  font-weight: var(--OC-GeneralTerm-title_variant-weight);
}

.OC-GeneralTerm-icon_color {
  color: var(--OC-GeneralTerm-icon_color);
}





























































.OC-border_position {
  border-style: var(--OC-border_position);
}