@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import "@fontsource/roboto";
@import "@fontsource/roboto/400.css";
@import "@fontsource/roboto/400-italic.css";

/* /////////////////// TOOLTIP ///////////////// */

.tooltip {
  padding: none;
  margin: none;
  border-radius: 3px;
  font-size: 90%;
  width: max-content;
}


/* /////////////////// MENU NOTIFICATIONS BANNER ///////////////// */

.animate-scroll {
    animation: scroll 50s linear infinite;
    animation-fill-mode: forwards;
  }

  @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-300%);
    }
  }

  /* Previne o flash no final da animação */
  @media (prefers-reduced-motion: no-preference) {
    .animate-scroll {
      animation-play-state: running;
    }
  }
/* /////////////////// Online Checkin TIMEPICKER ///////////////// */


.time-picker-scrollbar::-webkit-scrollbar {
    width: 4px;
  }

  .time-picker-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .time-picker-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  .time-picker-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

/* /////////////////// Online Checkin ///////////////// */

@layer utilities {
    .calendar-picker-white::-webkit-calendar-picker-indicator {
      filter: invert(1) brightness(100%);
    }

      /* Estilo para o texto selecionado */
  .ant-select-selection-selected-value.dark,
  .ant-select-selection-item.dark,
  .ant-select-selector.dark {
    @apply text-white;
  }

/* Remove padding horizontal do input */
.ant-select-selection-search-input {
  padding-left: 0 !important;
  padding-right: 0 !important;

}

/* Remove padding horizontal do placeholder */
.ant-select-selection-placeholder {
  padding-left: 0 !important;
  padding-right: 0 !important;
}


.ant-select {
  z-index: 0 !important;
}

.ant-select-focused .ant-select-selector {
  z-index: 0 !important;
}

/* Opcional: ajustar o wrapper também para manter consistência */
.ant-select-selection-wrap {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* Opcional: ajustar o wrapper também para manter consistência */
.ant-select-selector {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

  :where(.ant-select-dropdown) .ant-select-item-option-selected,
  :where(.ant-select-dropdown) .ant-select-item-option-selected span,
  .ant-select-selection-item,
  .ant-select-selection-item span {
    color: var(--OC-FormField-text_color) !important;
    font-size: var(--OC-FormField-text_size) !important;
    font-family: var(--OC-FormField-text_font_family) !important;
    font-style: var(--OC-FormField-text_variant-style) !important;
    font-weight: var(--OC-FormField-text_variant-weight) !important;
  }

    :where(.ant-select) .ant-select-selection-placeholder span {
    color: var(--OC-FormField-placeholder_color) !important;
    font-family: var(--OC-FormField-placeholder_font_family) !important;
    font-style: var(--OC-FormField-placeholder_variant-style) !important;
    font-weight: var(--OC-FormField-placeholder_variant-weight) !important;
  }

  .dark .ant-select-selection-placeholder span {
    color: white !important;
    font-family: var(--OC-FormField-placeholder_font_family) !important;
    font-style: var(--OC-FormField-placeholder_variant-style) !important;
    font-weight: var(--OC-FormField-placeholder_variant-weight) !important;
  }

  .dark .ant-select-dropdown,
  .dark .rc-virtual-list,
  .dark .ant-select-dropdown-placement-bottomLeft {
    scrollbar-width: thin !important;
    scrollbar-color: white transparent !important;
    border-radius: 8px !important;
  }

  .dark .ant-select-dropdown {
    border-radius: 8px !important;
  }

  .dark .ant-select-dropdown .ant-select-item-option {
    border-radius: 8px !important;
  }



      /* Estilo do dropdown */
  .ant-select-dropdown.dark {
    @apply bg-gxp_dark_3 border border-white;
  }

  .dark .rc-virtual-list-holder-inner {
    scrollbar-color: white transparent;
  }

  .dark .rc-virtual-list-scrollbar {
    width: 8px !important;
  }

  .dark .rc-virtual-list-scrollbar-thumb {
    background: white !important;
    border-radius: 4px;
  }

  /* Estilo das opções */
  .ant-select-item.dark {
    @apply text-white;
  }

  /* Opção hover */
  .ant-select-item-option-active.dark {
    @apply bg-gxp_dark_2;
  }

  .ant-select-selection-item.dark {
    @apply text-white;
  }

  /* Opção selecionada */
  .ant-select-item-option-selected.dark {
    @apply bg-gxp_dark_1;
  }

  /* Estilo da barra de busca */
  .ant-select-dropdown .ant-select-search.dark input {
    @apply bg-gxp_dark_3 text-white border-white;
  }

  /* Estilo do texto de não encontrado */
  .ant-select-empty.dark {
    @apply text-white;
  }
  }

  .swiper {
    z-index: 0 !important;
  }


.dark .ant-select-selection-item {
    color: white !important;
  }

  .dark .ant-select-selection-item span {
    color: white !important;
  }

/* /////////////////////////////////////////////////// */

* {
    scroll-behavior: smooth;
  }

.ant-select-selection-item{
    background-color: none!important;
}


.scrollbar-hide::-webkit-scrollbar {
    display: none;
}
.scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}


.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border: none!important;

}
.ant-select-selector{
    width: 40px;
    background-color: transparent!important;
}

.ant-select{
    padding-left: none;
    margin-left: none;
}


.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    box-shadow: none!important;
    padding: none!important;
}

.ant-select-single .ant-select-selector{
    font-size: 1.25rem;
    padding: none;
}


.ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector{
    border: none!important;
    box-shadow: none!important;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }

  input[type="color"] {
    -webkit-appearance: transparent;
    border: 1px solid gray;
    outline: none;
    background: none;         /* Remove o background padrão */
    border-radius: 8px;
    margin-bottom: 2px;
    height: 25px;
}
input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
    border: transparent;
    outline: transparent;

}
input[type="color"]::-webkit-color-swatch {
    border: transparent;
}

.diagonal-stripes {
  position: relative;
  border: 2px solid rgb(59, 130, 246) !important; /* azul do Tailwind blue-500 */
}

.diagonal-stripes::before {
  content: '';
  position: absolute;
  z-index: 15; /* Valor mais alto para elementos filhos */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: repeating-linear-gradient(
    45deg,
    transparent,
    transparent 5px,
    rgba(59, 130, 246, 0.3) 5px,
    rgba(59, 130, 246, 0.3) 7px
  );
  pointer-events: none;
}

.ant-select-suffix {
  pointer-events: all !important;
  cursor: pointer !important;
}

.ant-select-arrow {
  cursor: pointer !important;
  z-index: 1 !important;
}
