.MH-bg_color {
    background-color: var(--MH-bg_color) ;
}

.MH-title_color {
    color: var(--MH-title_color);
}

.MH-title_size {
    font-size: var(--MH-title_size) ;
}

.MH-title_font_family {
    font-family: var(--MH-title_font_family) ;
}

.MH-title_variant {
    font-style: var(--MH-title_variant-style)!important;
    font-weight: var(--MH-title_variant-weight)!important;
}

.MH-icon_color {
    color: var(--MH-icon_color);
}

.MH-border_color {
    border-color: var(--MH-border_color);
}

.MH-border_rounded {
    border-radius: var(--MH-border_rounded);
}

.MH-border_position {
    border-style: var(--MH-border_position);
}

.MH-border_width {
    border-width: var(--MH-border_width);
}

.MH-text_color {
    color: var(--MH-text_color) ;
}

.MH-text_size {
    font-size: var(--MH-text_size) ;
}

.MH-text_font_family {
    font-family: var(--MH-text_font_family);
}

.MH-text_variant {
    font-style: var(--MH-text_variant-style)!important;
    font-weight: var(--MH-text_variant-weight)!important;
}






