/*TABLE RESERVATION*/
.TR-bg_color {
    background-color: var(--TR-bg_color);
  }

.dark .TR-bg_color {
  background-color: theme('colors.gxp_dark_2');
}

.TR-title_color {
  color: var(--TR-title_color);
}

.dark .TR-title_color {
  color: text-white
}

.TR-title_size {
  font-size: var(--TR-title_size);
}

.TR-title_font_family {
  font-family: var(--TR-title_font_family) !important;
}

.TR-title_variant {
  font-style: var(--TR-title_variant-style);
  font-weight: var(--TR-title_variant-weight);
}

/*HEADER*/
.TR-Header-title_color {
  color: var(--TR-Header-title_color);
}

.dark.TR-Header-title_color {
  color: white
}

.TR-Header-title_size {
  font-size: var(--TR-Header-title_size);
}

.TR-Header-title_font_family {
  font-family: var(--TR-Header-title_font_family) !important;
}

.TR-Header-title_variant {
  font-style: var(--TR-Header-title_variant-style);
  font-weight: var(--TR-Header-title_variant-weight);
}

.TR-Header-text_color {
  color: var(--TR-Header-text_color);
}

.dark.TR-Header-text_color {
  color: white
}

.TR-Header-text_size {
  font-size: var(--TR-Header-text_size);
}

.TR-Header-text_font_family {
  font-family: var(--TR-Header-text_font_family) !important;
}

.TR-Header-text_variant {
  font-style: var(--TR-Header-text_variant-style);
  font-weight: var(--TR-Header-text_variant-weight);
}

.TR-Header-bar_bg_color {
  background-color: var(--TR-Header-bar_bg_color);
}

.dark.TR-Header-bar_bg_color {
  background-color: var(--TR-Header-bar_bg_color);
}

.TR-Header-bar_border_color {
  border-color: var(--TR-Header-bar_border_color);
}

.dark.TR-Header-bar_border_color {
  border-color: white;
}

.TR-Header-bar_border_width {
  border-width: var(--TR-Header-bar_border_width);
}

.TR-Header-bar_border_rounded {
  border-radius: var(--TR-Header-bar_border_rounded);
}

.TR-Header-bar_border_position {
  border-style: var(--TR-Header-bar_border_position);
}

/*STEPPER*/

.TR-Stepper-border_color {
  border-color: var(--TR-Stepper-border_color);
}

.dark.TR-Stepper-border_color {
  border-color: white;
}

.TR-Stepper-active_color {
  color: var(--TR-Stepper-active_color);
  border-color: var(--TR-Stepper-active_color);
}

.TR-Stepper-text_color {
  color: var(--TR-Stepper-text_color);
}

.TR-Stepper-text_font_family {
  font-family: var(--TR-Stepper-text_font_family) !important;
}

.TR-Stepper-text_variant {
  font-style: var(--TR-Stepper-text_variant-style);
  font-weight: var(--TR-Stepper-text_variant-weight);
}

.TR-Stepper-icon_color {
  color: var(--TR-Stepper-icon_color);
}

.TR-Stepper-hover_bg_color:hover {
  background-color: var(--TR-Stepper-hover_bg_color);
}

.TR-Stepper-bg_color {
  background-color: var(--TR-Stepper-bg_color);
}

/*FORM*/

.TR-Form-title_color {
  color: var(--TR-Form-title_color);
}

.TR-Form-title_size {
  font-size: var(--TR-Form-title_size);
}

.TR-Form-title_font_family {
  font-family: var(--TR-Form-title_font_family) !important;
}

.TR-Form-title_variant {
  font-style: var(--TR-Form-title_variant-style);
  font-weight: var(--TR-Form-title_variant-weight);
}

.TR-Form-text_color {
  color: var(--TR-Form-text_color);
}

.TR-Form-text_size {
  font-size: var(--TR-Form-text_size);
}

.TR-Form-text_font_family {
  font-family: var(--TR-Form-text_font_family) !important;
}

.TR-Form-text_variant {
  font-style: var(--TR-Form-text_variant-style);
  font-weight: var(--TR-Form-text_variant-weight);
}

.TR-Form-bg_color {
  background-color: var(--TR-Form-bg_color);
}

.TR-Form-border_color {
  border-color: var(--TR-Form-border_color);
}

.TR-Form-border_rounded {
  border-radius: var(--TR-Form-border_rounded);
}

.TR-Form-border_position {
  border-style: var(--TR-Form-border_position);
}

.TR-Form-border_width {
  border-width: var(--TR-Form-border_width);
}

/*CARD*/

.TR-Card-bg_color {
  background-color: var(--TR-Card-bg_color);
}

.TR-Card-text_color {
  color: var(--TR-Card-text_color);
}

.TR-Card-text_size {
  font-size: var(--TR-Card-text_size);
}

.TR-Card-text_font_family {
  font-family: var(--TR-Card-text_font_family) !important;
}

.TR-Card-text_variant {
  font-style: var(--TR-Card-text_variant-style);
  font-weight: var(--TR-Card-text_variant-weight);
}

.TR-Card-border_color {
  border-color: var(--TR-Card-border_color);
}

.TR-Card-border_rounded {
  border-radius: var(--TR-Card-border_rounded);
}

.TR-Card-border_rounded {
  border-radius: var(--TR-Card-border_rounded);
}

.TR-Card-border_position {
  border-style: var(--TR-Card-border_position);
}

.TR-Card-border_width {
  border-width: var(--TR-Card-border_width);
}

.TR-Card-hover_bg_color:hover {
  background-color: var(--TR-Card-hover_bg_color);
}

.TR-Card-hover_border_color:hover {
  border-color: var(--TR-Card-hover_border_color);
}

.TR-Card-active_bg_color {
  background-color: var(--TR-Card-active_bg_color);
}

.TR-Card-active_color {
  color: var(--TR-Card-active_color);
  border-color: var(--TR-Card-active_color);
}

.TR-Card-disabled_bg_color {
  background-color: var(--TR-Card-disabled_bg_color);
}

.TR-Card-disabled_text_color {
  color: var(--TR-Card-disabled_text_color);
}

.TR-Card-disabled_border_color {
  border-color: var(--TR-Card-disabled_border_color);
}


/*BUTTONS*/

.TR-Button1-bg_color {
  background-color: var(--TR-Button1-bg_color);
}


.TR-Button1-icon_color {
  color: var(--TR-Button1-icon_color);
}


.TR-Button1-border_color {
  border-color: var(--TR-Button1-border_color);
}


.TR-Button2-bg_color {
  background-color: var(--TR-Button2-bg_color);
}


.TR-Button2-icon_color {
  color: var(--TR-Button2-icon_color);
}


.TR-Button2-border_color {
  border-color: var(--TR-Button2-border_color);
}


/*FORM FIELDS*/

.TR-FormField-label_color {
  color: var(--TR-FormField-label_color);
}


.TR-FormField-label_size {
  font-size: var(--TR-FormField-label_size);
}


.TR-FormField-label_font_family {
  font-family: var(--TR-FormField-label_font_family) !important;
}


.TR-FormField-label_variant {
  font-style: var(--TR-FormField-label_variant-style);
  font-weight: var(--TR-FormField-label_variant-weight);
}


.TR-FormField-label_location {
  transform: translateY(var(--TR-FormField-label_location)) translateX(12px);
}


.TR-FormField-label_location_phone {
  transform: translateY(var(--TR-FormField-label_location)) translateX(155px);
}


.TR-FormField-text_color {
  color: var(--TR-FormField-text_color);
}


.TR-FormField-text_size {
  font-size: var(--TR-FormField-text_size);
}


.TR-FormField-text_font_family {
  font-family: var(--TR-FormField-text_font_family) !important;
}


.TR-FormField-text_variant {
  font-style: var(--TR-FormField-text_variant-style);
  font-weight: var(--TR-FormField-text_variant-weight);
}


.TR-FormField-bg_color {
  background-color: var(--TR-FormField-bg_color);
}


.TR-FormField-icon_color {
  color: var(--TR-FormField-icon_color);
}


.TR-FormField-border_color {
  border-color: var(--TR-FormField-border_color);
}


.TR-FormField-border_rounded {
  border-radius: var(--TR-FormField-border_rounded);
}


.TR-FormField-border_rounded_phone {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--TR-FormField-border_rounded);
  border-bottom-right-radius: var(--TR-FormField-border_rounded);
}


.TR-FormField-border_rounded_phone_select {
  border-top-left-radius: var(--TR-FormField-border_rounded);
  border-bottom-left-radius: var(--TR-FormField-border_rounded);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}


.TR-FormField-border_position {
  border-style: var(--TR-FormField-border_position);
}


.TR-FormField-border_width {
  border-width: var(--TR-FormField-border_width);
}


.TR-FormField-placeholder_color::placeholder {
  color: var(--TR-FormField-placeholder_color) !important;
}


.TR-FormField-placeholder_font_family::placeholder {
  font-family: var(--TR-FormField-placeholder_font_family) !important;
}


.TR-FormField-placeholder_variant::placeholder {
  font-style: var(--TR-FormField-placeholder_variant-style) !important;
  font-weight: var(--TR-FormField-placeholder_variant-weight) !important;
}



























































.OC-border_position {
  border-style: var(--OC-border_position);
}