.MF-bg_color {
    background-color: var(--MF-bg_color) ;
}

.MF-title_color {
    color: var(--MF-title_color);
}

.MF-title_size {
    font-size: var(--MF-title_size) ;
}

.MF-title_font_family {
    font-family: var(--MF-title_font_family) ;
}

.MF-title_variant {
    font-style: var(--MF-title_variant-style)!important;
    font-weight: var(--MF-title_variant-weight)!important;
}

.MF-icon_color {
    color: var(--MF-icon_color);
}

.MF-border_color {
    border-color: var(--MF-border_color);
}

.MF-border_rounded {
    border-radius: var(--MF-border_rounded);
}

.MF-border_position {
    border-style: var(--MF-border_position);
}

.MF-border_width {
    border-width: var(--MF-border_width);
}







